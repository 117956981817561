import React from 'react';
import Home from '../komponents/Home/Home';
import '../../scss/custom.scss';

function App() {
  return (
    <div className="application">
      <Home />
    </div>
  );
}

export default App;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Compara precios de soluciones de pago" />
    <title>Comparador de terminales - PuntoTerminal.mx</title>
    <link rel="canonical" href="https://www.puntoterminal.mx/" />
  </>
);
