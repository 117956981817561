import React from 'react';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { carouselProvider, slide } from './TerminalCarousel.module.scss';

import BillPocketTerminal from '../../../static/images/Terminal/BillPocketMini.png';
import ClipMini from '../../../static/images/Terminal/ClipMini.png';
import ClipInalambrico from '../../../static/images/Terminal/ClipInalambrico.png';
import GetNetMini from '../../../static/images/Terminal/GetNetMini.png';
import MercadoPagoMini from '../../../static/images/Terminal/MercadoPagoMini.png';
import MercadoPagoInalambrico from '../../../static/images/Terminal/MercadoPagoInalambrico.png';
import SrPagoMini from '../../../static/images/Terminal/SrPagoMini.png';
import UalaBisMini from '../../../static/images/Terminal/UalaBisMini.png';
import UalaBisInalambrico from '../../../static/images/Terminal/UalaBisInalambrico.png';
import YPCInalambrico from '../../../static/images/Terminal/YPCInalambrico.png';
import YPCMini from '../../../static/images/Terminal/YPCMini.png';
import ZettleMini from '../../../static/images/Terminal/ZettleMini.png';

const TerminalCarousel = () => (
  <CarouselProvider
    className={carouselProvider}
    interval={5800}
    isPlaying
    naturalSlideWidth={100}
    naturalSlideHeight={100}
    totalSlides={12}
    visibleSlides={6}
    infinite
    touchEnabled
    dragEnabled
  >
    <Slider className={slide}>
      <Slide index={0}>
        <Image src={BillPocketTerminal} hasMasterSpinner />
      </Slide>
      <Slide index={1}>
        <Image src={ClipMini} hasMasterSpinner />
      </Slide>
      <Slide index={2}>
        <Image src={ClipInalambrico} hasMasterSpinner />
      </Slide>
      <Slide index={3}>
        <Image src={GetNetMini} hasMasterSpinner />
      </Slide>
      <Slide index={4}>
        <Image src={MercadoPagoMini} hasMasterSpinner />
      </Slide>
      <Slide index={5}>
        <Image src={MercadoPagoInalambrico} hasMasterSpinner />
      </Slide>
      <Slide index={6}>
        <Image src={SrPagoMini} hasMasterSpinner />
      </Slide>
      <Slide index={7}>
        <Image src={UalaBisMini} hasMasterSpinner />
      </Slide>
      <Slide index={8}>
        <Image src={UalaBisInalambrico} hasMasterSpinner />
      </Slide>
      <Slide index={9}>
        <Image src={YPCInalambrico} hasMasterSpinner />
      </Slide>
      <Slide index={10}>
        <Image src={YPCMini} hasMasterSpinner />
      </Slide>
      <Slide index={11}>
        <Image src={ZettleMini} hasMasterSpinner />
      </Slide>
    </Slider>
  </CarouselProvider>
);

export default TerminalCarousel;
