// extracted by mini-css-extract-plugin
export var howItWorks = "TextGroup-module--howItWorks--811da";
export var mid = "TextGroup-module--mid--5b17c";
export var midImg = "TextGroup-module--midImg--78919";
export var midImgSmall = "TextGroup-module--midImgSmall--83b29";
export var midSmall = "TextGroup-module--midSmall--f9440";
export var pad = "TextGroup-module--pad--827a4";
export var terminalCarousel = "TextGroup-module--terminalCarousel--70abe";
export var textGroupContainer = "TextGroup-module--textGroupContainer--e7e3b";
export var title = "TextGroup-module--title--78568";
export var topBottom = "TextGroup-module--topBottom--ecb8a";