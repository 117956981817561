/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { home } from './Home.module.scss';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Hero from '../Hero/Hero';
import FAQ from '../FAQ/FAQ';
import TextGroup from '../TextGroup/TextGroup';
// import Flow from "../Flow/Flow";
import HeroBottom from '../HeroBottom/HeroBottom';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';
import useLanguageStore from '../LanguageStore/LanguageStore';
import BlogPreview from '../BlogPreview/BlogPreview';

type LanguageContent = {
  AgreeButton: string;
  CookiePolicyText: string;
  PrivacyPolicy: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  AgreeButton: 'Agree',
  CookiePolicyText:
    'This site uses cookies to provide services at the highest level level. By continuing to use the site, you agree to the terms their use.',
  PrivacyPolicy: 'Privacy Policy',
};
content.Español = {
  AgreeButton: 'Acuerdo',
  CookiePolicyText:
    'Este sitio utiliza cookies para proporcionar servicios al más alto nivel. Al continuar usando el sitio, aceptas su uso. política de privacidad',
  PrivacyPolicy: 'Privacidad y Cookies',
};

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap');
</style>;

const Home = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  const resetFlowProgress = useFlowProgressStore(
    (state) => state.resetFlowProgress
  );

  useEffect(() => {
    resetFlowProgress();
  }, []);

  return (
    <div className={home}>
      <Header />
      <CookieConsent
        acceptOnScroll
        acceptOnScrollPercentage={50}
        location="bottom"
        buttonText={choosenLanguage.AgreeButton}
        style={{
          background: '#ECEBE4',
          color: '#444d55',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0',
        }}
        buttonStyle={{
          background: '#444d55',
          color: '#fff',
          broderRadius: '30px',
          fontWeight: 600,
          fontSize: '14px',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          fontFamily: 'montserrat',
          borderRadius: '3px',
          margin: '5px 35px',
        }}
        expires={365}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {' '}
          {choosenLanguage.CookiePolicyText}{' '}
          <Link
            to="/privacidad"
            style={{
              textDecoration: 'none',
              color: '#685641',
            }}
          >
            {choosenLanguage.PrivacyPolicy}
          </Link>
        </p>
      </CookieConsent>
      <Hero />
      <TextGroup />
      <HeroBottom />
      <FAQ />
      {/* <BlogPreview /> */}
      <Footer />
    </div>
  );
};

export default Home;
