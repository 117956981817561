import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import useLanguageStore from '../LanguageStore/LanguageStore';
import {
  title,
  howItWorks,
  pad,
  topBottom,
  mid,
  midImg,
  midImgSmall,
  midSmall,
  textGroupContainer,
  terminalCarousel,
} from './TextGroup.module.scss';
// import { withPrefix } from 'gatsby';
import savingsBlackIcon from '../../../static/images/png/savings.png';
import receiptBlackIcon from '../../../static/images/png/receipt.png';
import thumbUpBlackIcon from '../../../static/images/png/thumb-up.png';
import TerminalCarousel from '../TerminalCarousel/TerminalCarousel';

type LanguageContent = {
  title: string;
  text1: string;
  text2: string;
  text3: string;
  compare: string;
  compare2: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  title: 'How it works',
  text1:
    'Upload your invoice or answer a few simple questions\n' +
    'about your company and your current payment solution.',
  text2:
    'We will search the market for the best prices \nand ' +
    'find out how much your company \ncan save when changing payment solution!',
  text3:
    'Choose the best offer and start saving money. \nPuntoTerminal lowers' +
    ' the cost for your company\n with up to 40% per year!',
  compare: 'Compare and choose',
  compare2: 'Between the best terminals in Mexico',
};
content.Español = {
  title: '¿Cómo funciona?',
  text1:
    'Responde algunas preguntas simples \n' +
    'sobre tu empresa y tu servicio de pago actual.',
  text2:
    'Nosotros investigamos el mercado por ti y\nencontramos los mejores ' +
    'precios para tu empresa. \n¡Solo toma un minuto!',
  text3:
    '¡Elige la mejor oferta y empieza ahorrar hoy! ' +
    '\nPuntoTerminal puede disminuir tus costos de procesar tarjetas hasta 40% cada año.',
  compare: 'Compara y elige',
  compare2: 'Las mejores terminales de México',
};

function Text(props: { text: string; image: any; alt: string; rev: boolean }) {
  if (props.rev) {
    return (
      <>
        <Row className={howItWorks}>
          {/* If screen size is small */}
          <Col lg="1" md="4">
            <Image
              className={midImgSmall}
              src={props.image}
              alt={props.alt}
              title={props.alt}
              roundedCircle
            />
          </Col>
          <Col lg="11" md="8">
            <h3 className={midSmall}>{props.text}</h3>
          </Col>

          {/* Normal screen size */}
          <div className="justify-content-end d-flex">
            <Col lg="11" md="8">
              <h3 className={mid}>{props.text}</h3>
            </Col>
            <Col lg="1" md="4">
              <Image
                className={midImg}
                src={props.image}
                alt={props.alt}
                title={props.alt}
                roundedCircle
                fluid
              />
            </Col>
          </div>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row className={howItWorks}>
        <Col lg="1" md="4">
          <Image
            src={props.image}
            alt={props.alt}
            title={props.alt}
            roundedCircle
          />
        </Col>
        <Col lg="11" md="8">
          <h3 className={topBottom}>{props.text}</h3>
        </Col>
      </Row>
    </>
  );
}

const TextGroup = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  // const handleClick = (num: number) => {
  //   props.toggleFlow(num);
  // };

  return (
    <div className={textGroupContainer}>
      <div>
        {/* <Button onClick={() => handleClick(5)}> */}
        {/* <h3 className={title}>{choosenLanguage.title}</h3> */}
        {/* </Button> */}
        <h2 className={title}>{choosenLanguage.title}</h2>
      </div>
      <div className={pad} />
      <Container>
        <Text
          text={choosenLanguage.text1}
          image={savingsBlackIcon}
          alt="alcancia"
          title="alcancia"
          rev={false}
        />
        <div className={pad} />
        <Text
          text={choosenLanguage.text2}
          image={receiptBlackIcon}
          alt="recibo"
          title="recibo"
          rev
        />
        <div className={pad} />
        <Text
          text={choosenLanguage.text3}
          image={thumbUpBlackIcon}
          alt="Pulgar arriba"
          title="Pulgar arriba"
          rev={false}
        />

        <div className={pad} />
        <div className={pad} />
        <h3 className={title}>{choosenLanguage.compare}</h3>
        <p>{choosenLanguage.compare2}</p>
        <div className={pad} />
        <div className={terminalCarousel}>
          <TerminalCarousel />
        </div>
      </Container>
      <div className={pad} />
    </div>
  );
};

export default TextGroup;
